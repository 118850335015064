import { useEffect, useState } from 'react';
import { ShopifyAppHook } from '../constants';
import { useGetAssetList } from './use-get-asset-list';
import { useAnimationBuilder } from '../../animation-builder/hooks/use-animation-builder';

export const useProductList = () => {
  const { assetList } = useGetAssetList();

  useEffect(() => {
    console.info('useProductList');
  }, []);

  return {
    assetPanelProps: {
      assets: assetList,
    },
    useUpdateAsset: () => {
      return { mutateAsync: () => null };
    },
    useUpdateAssetSegmentStatus: () => null,
    setCurrentTypeFilters: () => null,
  };
};

export const useAssetSearch = () => {
  useEffect(() => {
    console.info('useAssetSearch');
  }, []);

  return;
};

export const useAssetPolling = () => {
  useEffect(() => {
    console.info('useAssetPolling');
  }, []);

  return;
};

export const useUploadAudio = () => {
  useEffect(() => {
    console.info('useMusic');
  }, []);

  return;
};

export const useSample = () => {
  useEffect(() => {
    console.info('useSample');
  }, []);

  return;
};

export const useEditorPermissions = () => {
  useEffect(() => {
    console.debug('useEditorPermissions');
  }, []);

  return { hasAccess: true };
};

export const usePermissions = () => {
  const [_, setUserPermission] = useState('');
  useEffect(() => {
    console.info('useSample');
  }, []);

  return { hasAccess: true, setUserPermission, useEditorPermissions };
};

const useShopifyAppHookSampleMap = {
  [ShopifyAppHook.ASSETS]: useProductList,
  [ShopifyAppHook.AUDIO]: useUploadAudio,
  [ShopifyAppHook.ASSET_SEARCH]: useAssetSearch,
  [ShopifyAppHook.ASSET_POLLING]: useAssetPolling,
  [ShopifyAppHook.META_AUDIO]: useUploadAudio,
  [ShopifyAppHook.META_TAGS]: useSample,
  [ShopifyAppHook.ANIMATION_BUILDER]: useAnimationBuilder,
  [ShopifyAppHook.PERMISSIONS]: usePermissions,
};

export const useShopifyAppHook = ({ hookType }: { hookType: ShopifyAppHook }) =>
  useShopifyAppHookSampleMap[hookType]?.();
